<template>
  <el-card class="box-card feabox" style="margin-top: 30px">
    <div class="ordersbox">
      <div class="orderitem">
        <span class="desctitle">待付款订单(笔)</span>
        <span class="descdata">{{ newCreatedCount }}</span>
      </div>
      <div class="orderitem">
        <span class="desctitle">待发货订单(笔)</span>
        <span class="descdata">{{ paidCount }}</span>
      </div>
      <div class="orderitem">
        <span class="desctitle">已完成订单(笔)</span>
        <span class="descdata">{{ doneCount }}</span>
      </div>

      <div class="orderitem">
        <span class="desctitle">已售罄商品</span>
        <span class="descdata">{{ selloutCount }}</span>
      </div>
    </div>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      newCreatedCount: '',
      paidCount: '',
      doneCount: '',
      selloutCount: ''
    }
  },
  created() {
    this.getsummaryInfo()
  },
  methods: {
    async getsummaryInfo() {
      const res = await this.$http.get('/nktdec/dashboard/statdata')
      const { newCreatedCount, paidCount, doneCount, selloutCount } = res.data
      this.newCreatedCount = newCreatedCount
      this.paidCount = paidCount
      this.doneCount = doneCount
      this.selloutCount = selloutCount
    }
  }
}
</script>
<style scoped>
.ordersbox {
  display: flex;
  justify-content: flex-start;
  padding: 30px;
}
.orderitem {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.orderitem .desctitle {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 15px;
}
.orderitem .descdata {
  font-weight: 700;
  color: red;
  font-size: 16px;
}
</style>
