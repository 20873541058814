<template>
  <el-card class="box-card feabox">
    <template #header>
      <div class="card-header tododaytitle">
        <h4>实时概况</h4>
      </div>
    </template>
    <ul class="lisbox">
      <li class="">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-renminbi"></use>
          </svg>
        </div>
        <div class="infobox">
          <span class="infoname">销售额（元）</span>
          <span class="infodata">{{ allValue }}</span>
        </div>
      </li>
      <li class="">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shangpin-"></use>
          </svg>
        </div>
        <div class="infobox">
          <span class="infoname">商品总数量</span>
          <span class="infodata">{{ allProNum }}</span>
        </div>
      </li>
      <li class="">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-dingdan"></use>
          </svg>
        </div>
        <div class="infobox">
          <span class="infoname"> 付款订单总量 </span>
          <span class="infodata">{{ payNum }}</span>
        </div>
      </li>
      <li class="">
        <div class="font">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-ren1"></use>
          </svg>
        </div>
        <div class="infobox">
          <span class="infoname">消费总人次</span>
          <span class="infodata">{{ allPerson }}</span>
        </div>
      </li>
    </ul>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      allValue: '',
      allProNum: '',
      payNum: '',
      allPerson: ''
    }
  },
  created() {
    this.getsummaryInfo()
  },
  methods: {
    async getsummaryInfo() {
      const res = await this.$http.get('/nktdec/dashboard/statdata')
      const { mallNumber, groupNumber, liveNumber, classNumber, productCount, classCount, paidCount, newCreatedCount, deliveryedCount, doneCount, cancelledCount } = res.data
      var str = (mallNumber + groupNumber + liveNumber + classNumber) + ''
      if (str.split('.')[1]) {
        if (str.split('.')[1].length === 1) {
          this.allValue = str + '0'
        } else {
          this.allValue = str
        }
      } else {
        this.allValue = str + '.00'
      }
      this.allProNum = productCount + classCount
      this.payNum = paidCount
      this.allPerson = paidCount + newCreatedCount + deliveryedCount + doneCount + cancelledCount
    }
  }
}
</script>
<style scoped>
.tododaytitle {
  line-height: 0;
  padding: unset;
  text-align: left;
}
.lisbox {
  display: flex;
  justify-content: space-between;
}
.lisbox li {
  /* background-color: pink; */
  width: 300px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  /* height: 120px; */
}
.font {
  font-size: 44px;
}
.infobox {
  display: flex;
  margin-left: 10px;
  flex-direction: column;
}
.infoname {
  font-size: 14px;
  color: #666;
}
.infodata {
  color: #000;
  font-size: 24px;
  margin-top: 8px;
}
</style>
