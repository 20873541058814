<template>
  <div>
    <SummaryInfo></SummaryInfo>
    <OrderSumarry></OrderSumarry>
    <QuickOption></QuickOption>
  </div>
</template>
<script>
import SummaryInfo from './SummaryInfo/SummaryInfo'
import QuickOption from './QuickOption/QuickOption'
import OrderSumarry from './ordersum/OrderSumarry'
export default {
  data() {
    return {
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      }]
    this.$store.commit('setBreadInfo', Arr)
  },
  components: {
    QuickOption,
    SummaryInfo,
    OrderSumarry
  }
}
</script>
<style scoped>
</style>
